:root {
  --purple: #5542f6;
  --purple-1: #e666e5;
  --purple-2: #c8c2fc;
  --blue: #0049c8;
  --blue-1: #3e8cff;
  --blue-2: #99b6e9;
  --red: #ee4540;
  --green: #20c9ac;
  --green-1: #14b13b;
  --green-2: #5ed8c3;
  --orange: #fea043;
  --orange-1: #ffdfc1;
  --gray: #84838a;
  --gray-1: #dbdadb;
  --gray-2: #f3f2f3;
  --sidebar-width: 280px;
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Nunito Sans", sans-serif;
  color: #333239;
  background-color: #fafbfc;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: var(--blue);
}

button {
  user-select: none;
  cursor: pointer;
}
button :hover {
  outline: none;
}
button :active {
  outline: none;
}
button:disabled {
  pointer-events: none;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.slide-in {
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-muted {
  color: var(--gray);
}

.text-success {
  color: var(--green);
}

.text-warning {
  color: var(--orange);
}

.text-info {
  color: var(--purple);
}

.text-primary {
  color: var(--blue);
}

.text-danger {
  color: var(--red);
}

.text-monospace {
  font-family: monospace;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
/*# sourceMappingURL=index.a51530ca.css.map */
